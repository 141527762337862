var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon
      }
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              "no-maximize": "",
                              title: "General",
                              expandable: ""
                            }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4", md: "4", lg: "4" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Name",
                                        name: "name",
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value:
                                          _vm.formData["specificationName"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "specificationName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData['specificationName']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Start Date",
                                        name: "startTime"
                                      },
                                      model: {
                                        value: _vm.formData["startTime"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "startTime",
                                            $$v
                                          )
                                        },
                                        expression: "formData['startTime']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "End Date",
                                        name: "endTime"
                                      },
                                      model: {
                                        value: _vm.formData["endTime"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "endTime", $$v)
                                        },
                                        expression: "formData['endTime']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "9" } },
                                  [
                                    _c("asyent-form-textarea", {
                                      attrs: {
                                        "view-mode": _vm.viewMode,
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        flat: "",
                                        label: "Specification Description",
                                        name: "specificationDescription"
                                      },
                                      model: {
                                        value:
                                          _vm.formData[
                                            "specificationDescription"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "specificationDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formData['specificationDescription']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "12" } },
                                  [
                                    _c("AsyentCodeArea", {
                                      attrs: {
                                        name: "Specification",
                                        referefence: "specification",
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value: _vm.formData["specification"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "specification",
                                            $$v
                                          )
                                        },
                                        expression: "formData['specification']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.formValid,
          callback: function($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }